.button {
  @apply inline-block rounded-lg px-4 py-2 text-center align-middle font-heading;

  --btn-color: currentColor;
  --btn-bg: transparent;
  --btn-border-color: transparent;
  --btn-hover-border-color: transparent;

  color: var(--btn-color);
  background-color: var(--btn-bg);
  border: 1px solid var(--btn-border-color);

  &:hover,
  &:active {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
  }

  &:disabled {
    @apply pointer-events-none;

    color: var(--btn-color);
    background-color: var(--btn-bg);
    border-color: var(--btn-border-color);
    opacity: 0.65;
  }
}

.button-blue {
  --btn-color: theme(colors.white);
  --btn-bg: theme(colors.blue.DEFAULT);
  --btn-border-color: theme(colors.blue.DEFAULT);
  --btn-hover-color: theme(colors.white);
  --btn-hover-bg: theme(colors.blue.900);
  --btn-hover-border-color: theme(colors.blue.900);

  &:focus-visible {
    @apply ring-blue-900 ring-opacity-60;
  }
}

.button-yellow {
  --btn-color: theme(colors.black);
  --btn-bg: theme(colors.yellow.DEFAULT);
  --btn-border-color: theme(colors.yellow.DEFAULT);
  --btn-hover-color: theme(colors.black);
  --btn-hover-bg: theme(colors.yellow.600);
  --btn-hover-border-color: theme(colors.yellow.600);

  &:focus-visible {
    @apply ring-yellow-600 ring-opacity-60;
  }
}

.button-orange {
  --btn-color: theme(colors.black);
  --btn-bg: theme(colors.orange.DEFAULT);
  --btn-border-color: theme(colors.orange.DEFAULT);
  --btn-hover-color: theme(colors.black);
  --btn-hover-bg: theme(colors.orange.600);
  --btn-hover-border-color: theme(colors.orange.600);

  &:focus-visible {
    @apply ring-orange-600 ring-opacity-60;
  }
}

.button-green {
  --btn-color: theme(colors.black);
  --btn-bg: theme(colors.green.DEFAULT);
  --btn-border-color: theme(colors.green.DEFAULT);
  --btn-hover-color: theme(colors.black);
  --btn-hover-bg: theme(colors.green.600);
  --btn-hover-border-color: theme(colors.green.600);

  &:focus-visible {
    @apply ring-green-600 ring-opacity-60;
  }
}
